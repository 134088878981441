import { css } from "styled-components";
/*
  font @import rules are not working with styled-component global
  rules at this time, so we use css or Helmet to inject them as a global style.
*/
// Example:
// export default = createGlobalStyle`
//   @import url("https://lolstatic-a.akamaihd.net/webfonts/live/css/lor/fonts.css");
// `;
// Or fill up the theme-fonts.css file to load custom fonts.
import "./theme-fonts.css";

/** Colors */

const text_color_primary = "#ffffff";
const text_color_secondary = "#a8a8a8";
const text_color_tertiary = "#d8d8d8";
const text_color_dark = "#000000";

const dark_color_primary = "#111616";
const dark_color_secondary = "#131416";
const dark_color_tertiary = "#000000";

const middle_color_primary = "#444D4D";

const color_rule = "#1E2323";

const color_accent = "#5AE6BD";
const color_accent_secondary = "#5AE6BD";
const color_accent_dark = "#59BD95";
const color_accent_light = "#5AE6BD";
const color_accent_lighter = "#63FFD4";
const color_victory = "#6a66ff";
const color_victory_dark = "#6a66ff";

/** Themes */

export const main_theme = {
  color_background_primary: dark_color_primary,
  color_background_secondary: dark_color_secondary,
  color_background_tertiary: dark_color_tertiary,
  color_navigation: text_color_tertiary,
  color_accent: color_accent,
  color_accent_secondary: color_accent_secondary,
  color_accent_dark: color_accent_dark,
  color_accent_light: color_accent_light,
  color_accent_lighter: color_accent_lighter,
  middle_color_primary: middle_color_primary,
  color_rule: color_rule,
  color_victory: color_victory,
  color_victory_dark: color_victory_dark,
  color_heading: text_color_primary,
  color_text: text_color_primary,
  color_text_dark: text_color_dark,
  color_text_dimmed: text_color_secondary,
  color_text_accent: color_accent,
  font_family_main: `"Source Sans Pro", sans-serif`,
  font_family_header: `"Bebas Neue", "Source Sans Pro", sans-serif`,
  supports_uppercasing: true,
  gutter_size_large: "32px",
  gutter_size_medium: "24px",
  gutter_size_small: "14px",
};

export type Theme = typeof main_theme;

/** Mixins */

// use this mix-in instead of `text-transform: uppercase`
export const TextTransformUppercase = css`
  text-transform: ${(props) => (props.theme.supports_uppercasing ? "uppercase" : "none")};
`;

// Main font, to use for most text.
export const StyleFontMain = css`
  font-family: ${(props) => props.theme.font_family_main};
  letter-spacing: normal;
  font-weight: 200;
`;

export const StyleFontHeader = css`
  font-family: ${({ theme }) => theme.font_family_header};
  ${TextTransformUppercase}
`;

export const StyledScrollbar = css`
  scrollbar-color: ${({ theme }) => theme.color_accent} transparent;
  scrollbar-width: thin;

  @media (pointer: fine) {
    ::-webkit-scrollbar {
      background-color: ${({ theme }) => theme.color_background_tertiary};
      height: 14px;
      margin: 0 10px;
      width: 14px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.color_accent};
      border: 4px solid ${({ theme }) => theme.color_background_tertiary};
      border-radius: 0;
    }

    ::-webkit-scrollbar-corner {
      background: red;
    }
  }
`;

const size = {
  small: "820px",
  medium_start: "821px",
  medium: "1075px",
  large: "1076px",
};

export const device = {
  small: `(max-width: ${size.small})`,
  small_and_medium: `(max-width: ${size.medium})`,
  medium: `(min-width: ${size.medium_start}) and (max-width: ${size.medium})`,
  medium_and_large: `(min-width: ${size.medium_start})`,
  large: `(min-width: ${size.large})`
};
