import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import React, { Fragment } from "react";
import { StyledFooter } from "./style";

type FooterLink = {
  link: string
  locKey: string
  openCookiePreferences?: boolean
};

// TODO verify that these are correct, and figure out what the Cookie Preferences
//      link should do.
const links: FooterLink[] = [
  {
    link: "https://www.riotgames.com/en/terms-of-service",
    locKey: "footer.termsOfService"
  },
  {
    link: "https://www.riotgames.com/en/privacy-notice",
    locKey: "footer.privacyNotice"
  },
  {
    link: "https://www.riotgames.com/en/accessibility",
    locKey: "footer.accessibility"
  },
  {
    link: "#",
    locKey: "footer.cookiePreferences",
    openCookiePreferences: true
  }
];

const Footer: React.FC<{}> = () => {
  const { t } = useTranslation();

  const handleCookiePreferences = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    try {
      (window as any).Osano?.cm?.showDrawer?.();
    } catch (err) {
      console.error("Error opening cookie preferences.");
    }
  };

  return (
    <StyledFooter>
      <div />
      <div className="ride-side">
        <div>{t("footer.copyright")}</div>
        <div>
          {links.map((link, i) => (
            <Fragment key={i}>
              <a
                className="footer-link"
                href={link.link}
                target="_blank"
                onClick={
                  (event) =>
                    link.openCookiePreferences && handleCookiePreferences(event)
                }
              >
                {t(link.locKey)}
              </a>
              {i < links.length - 1 && ` | `}
            </Fragment>
          ))}
        </div>
      </div>
    </StyledFooter>
  );
};

export default Footer;
