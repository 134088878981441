import { withPrefix } from "gatsby-link";
import { fetch } from "../fetchUtils";
import {
  AboutConfig,
  Leaderboards,
  MatchHistory,
  PastWinners,
  StatsLeaderboard,
  TopEarners
} from "./index.type";

const getUrl = (path: string) => {
  const base =
    process.env.GATSBY_CHAMPIONS_QUEUE_DATA_URL || "/championsQueueData/mocks";

  return withPrefix(`${base}${path}`);
};

export const fetchLeaderboard = async (): Promise<Leaderboards | Error> => {
  const url = getUrl("/leaderboards.json");
  return await fetch(url);
};

export const fetchAboutConfig = async (): Promise<AboutConfig | Error> => {
  const url = getUrl("/about.json");
  return await fetch(url);
};

export const fetchTopEarners = async (): Promise<TopEarners | Error> => {
  const url = getUrl("/topEarners.json");
  return await fetch(url);
};

export const fetchPastWinners = async (): Promise<PastWinners | Error> => {
  const url = getUrl("/hallOfChampions.json");
  return await fetch(url);
};

export const fetchStatsLeaderboard =
  async (): Promise<StatsLeaderboard | Error> => {
    const url = getUrl("/players.json");
    return await fetch(url);
  };

export const fetchMatchHistory = async (): Promise<MatchHistory | Error> => {
  const url = getUrl("/matches.json");
  return await fetch(url);
};
