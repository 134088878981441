import { useCallback, useEffect, useState } from "react";

export type Result<T> = {
  isLoading: boolean
  response: T | null
  error: Error | null
};

export type Fetcher<T> = Result<T> & {
  fetch: () => Promise<void>
};

export const useFetcher = <T> (service: () => Promise<T | Error>): Fetcher<T> => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const fetch = useCallback(async () => {
    setIsLoading(true);

    const newResponse = await service();

    setResponse(newResponse instanceof Error ? null : newResponse);
    setError(newResponse instanceof Error ? newResponse : null);

    setIsLoading(false);
  }, [service]);

  return { isLoading, response, error, fetch };
};

export const useFetch =
  <T> (
    service: () => Promise<T | Error>,
    deps: ReadonlyArray<unknown>
  ): Result<T> => {
    const { isLoading, response, error, fetch } = useFetcher(service);

    useEffect(() => {
      if (deps.every((dep) => dep !== undefined && dep !== null)) {
        void fetch();
      }
    }, deps);

    return { isLoading, response, error };
  };
