import styled from "styled-components";
import { device, TextTransformUppercase } from "../Layout/theme";

export const StyledHeader = styled.header`
  background: ${({ theme }) => theme.color_background_secondary};

  nav {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      ${TextTransformUppercase}
      font-size: 14px;
      font-weight: bold;
      color: ${({ theme }) => theme.color_navigation};
      margin: 0 5px;
      padding: 5px;
    }
  }

  @media ${device.small} {
    nav {
      flex-direction: column;
    }
  }
`;

export const StyledDivider = styled.span`
  width: 1px;
  height: 14px;
  background-color: ${({ theme }) => theme.color_navigation};

  @media ${device.small} {
    display: none;
  }
`;
