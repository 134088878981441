import styled from "styled-components";
import { StyleFontHeader } from "../../Layout/theme";

export const StyledInformLoading = styled.div`
  text-align: center;
  padding: 80px 0;

  .header {
    ${StyleFontHeader}
    font-size: 60px;
  }
`;
