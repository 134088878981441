export const fetch =
  async <T> (url: string, init?: RequestInit): Promise<T | FetchError> => {
    try {
      const response = await window.fetch(url, init);

      const isJson =
        response.headers.get("Content-Type")?.includes("application/json");
      const data = await (isJson ? response.json() : response.text());

      if (response.ok) {
        return data as T;
      }
      else {
        return new NonOkFetchError(response, data);
      }
    } catch (e) {
      return new NetworkingFetchError(e);
    }
  };

abstract class FetchError extends Error {}

/**
 * An error indicating that the server responded with a non-OK status code that is
 * not 2xx.
 */
class NonOkFetchError extends FetchError {
  readonly response: Response;

  constructor(response: Response, data: any) {
    super(
      (typeof data === "string")
        ? data
        : `Unknown fetch error: ${JSON.stringify(data)}`
    );
    this.response = response;
  }

  statusCode(): number {
    return this.response.status;
  }
}

/**
 * Indicates that the window.fetch call itself threw an exception indicating that
 * there was a networking-related error. This should be due to the user not having
 * internet access or the server being down.
 */
class NetworkingFetchError extends FetchError {
  readonly cause: any;

  constructor(cause: any) {
    super(`Networking fetch error: ${cause}`);
    this.cause = cause;
  }
}
