import React from "react";
import { useTranslation } from "react-i18next";
import { StyledInformLoading } from "./style";

const InformLoading: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledInformLoading>
      <div className="header">{t("inform.loading")}</div>
    </StyledInformLoading>
  )
};

export default InformLoading;
