import styled, { createGlobalStyle } from "styled-components";
import { StyleFontMain, Theme } from "./theme";

/**
 * Global styles let you style outer tags like html or body.
 * As a general rule, try to keep global styles to a minimum since these
 * can silently affect any other styles.
 * https://styled-components.com/docs/api#createglobalstyle
 */
export const GlobalStyles = createGlobalStyle<{ theme: Theme }>`
  html {
    background: ${(props) => props.theme.color_background_secondary};
  }

  body .osano-cm-widget {
    display: none;
  }
`;

export const StyledLayout = styled.div`
  width: 100%;
  height: 100%;
  color: ${(props) => props.theme.color_text};
  margin: 0;
  font-size: 1.6rem;
  ${StyleFontMain}

  h1 {
    font-size: 3rem;
    font-weight: bold;
    color: ${(props) => props.theme.color_heading_1};
  }

  p {
    margin: 1rem 0;
  }

  strong {
    font-weight: bold;
  }

  em {
    color: ${({ theme }) => theme.color_text_accent};
    font-style: italic;
  }

  a {
    color: ${({ theme }) => theme.color_text_accent};
    text-decoration: none;
  }
`;

export const StyledLayoutContentArea = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
`;
