import Fuse from "fuse.js";

export const cls = (...strs: (string | boolean | number | undefined | null)[]) =>
  strs.filter(Boolean).join(" ");

export function isSameDay (timestamp1: number, timestamp2: number) {
  // find out if two timestamps pertain to the same day (their year, month and day are the same)
  const date1 = new Date(timestamp1).setHours(0, 0, 0, 0);
  const date2 = new Date(timestamp2).setHours(0, 0, 0, 0);

  return date1 === date2;
};

export const Times = {
  Day: 24 * 60 * 60 * 1000,
  Days: 24 * 60 * 60 * 1000,
  Hour: 60 * 60 * 1000,
  Hours: 60 * 60 * 1000,
  Minute: 60 * 1000,
  Minutes: 60 * 1000,
  Second: 1000,
  Seconds: 1000
};

export const sliceIntoChunks = <T extends any> (
  arr: T[],
  chunkSize: number
): T[][] => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
  }
  return res;
};

type PlayerSearchItem<T> = {
  player: T
  playerName: string
  summonerName?: string
  teamName?: string
};

const createSearchItem = <T extends any>(
  player: T,
  playerName: string
): PlayerSearchItem<T> => {
  // Look for a team tricode followed by a space and then the summoner name, for
  // example "DIG River".
  const firstSpace = playerName.indexOf(" ");

  if (firstSpace > 0) {
    const teamName = playerName.slice(0, firstSpace);
    const summonerName = playerName.slice(firstSpace + 1);

    // Only consider it a team name if it's 2-4 characters, for example "DIG".
    if (teamName.length >= 2 && teamName.length <= 4) {
      return { player, playerName, summonerName, teamName };
    }
  }

  return { player, playerName };
};

export const createFuse = <T extends any>(
  players: T[],
  getPlayerName: (player: T) => string
) => {
  return new Fuse(
    players.map((player) => createSearchItem(player, getPlayerName(player))),
    {
      keys: [ "playerName", "summonerName", "teamName" ],
      threshold: 0.4,
      distance: 20,
      includeScore: true
    }
  );
};
