/**
 * Layout component that queries for data
 * with the useSiteMetadata hook.
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 * See: https://www.gatsbyjs.com/blog/2019-02-20-introducing-use-static-query/#after
 */

import React from "react";
import PropTypes from "prop-types";
import Header from "../Header";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import { NormalizeStyles } from "./normalize";
import { StyledLayout, GlobalStyles } from "./style";
import { main_theme } from "./theme";
import { StyleSheetManager, ThemeProvider } from "styled-components";
import { supportsUppercasing } from "../../helpers/rioti18n";
import { Helmet } from "react-helmet";
import stylisRTLCSS from "stylis-rtlcss";
import Footer from "../Footer";
import "@fontsource/source-sans-pro";
import "@fontsource/source-sans-pro/700.css";
import "@fontsource/source-sans-pro/900.css";
import "@fontsource/bebas-neue";

const Layout: React.FC = ({ children }) => {
  const { t } = useTranslation("first");
  const { language } = useI18next();
  const today = new Date();
  const date = new Intl.DateTimeFormat(language).format(today);

  // You can use static data from siteMetadata
  // const { title } = useSiteMetadata();

  // Load the main theme.
  const theme = {
    ...main_theme,
    // Uppercasing is not supported in some languages.
    supports_uppercasing: supportsUppercasing(language),
  };

  const isRTL = language === "ar-ae";

  return (
    <StyleSheetManager stylisPlugins={isRTL ? [stylisRTLCSS] : []}>
      <ThemeProvider theme={theme}>
        <Helmet>
          <html lang={language} dir={isRTL ? "rtl" : ""} />
        </Helmet>
        <NormalizeStyles />
        <GlobalStyles />
        <StyledLayout>
          <Header />
          <main>{children}</main>
          <Footer />
        </StyledLayout>
      </ThemeProvider>
    </StyleSheetManager>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
