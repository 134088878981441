import styled from "styled-components";
import { device } from "../Layout/theme";

export const StyledFooter = styled.footer`
  background: ${({ theme }) => theme.color_accent};
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.color_text_dark};
  font-size: 12px;

  .ride-side {
    text-align: right;

    .footer-link {
      color: ${({ theme }) => theme.color_text_dark};
      padding: 0 3px;
    }
  }

  @media ${device.large} {
    padding: ${({ theme }) => theme.gutter_size_large};
  }

  @media ${device.medium} {
    padding: ${({ theme }) => theme.gutter_size_medium};
  }

  @media ${device.small} {
    padding: ${({ theme }) => theme.gutter_size_small};
  }
`;
