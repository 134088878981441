import { StaticImage } from "gatsby-plugin-image";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import { StyledDivider, StyledHeader } from "./style";

const Header: React.FC<{}> = () => {
  const { t } = useTranslation();

  return (
    <StyledHeader>
      <nav>
        <Link to="/">
          <StaticImage
            src="../../images/emea_icon.png"
            height={30}
            alt={t("nav.logo")}
          />
        </Link>
        <Link to="/">{t("nav.leaderboard")}</Link>
        <StyledDivider />
        <Link to="/hall-of-champions">{t("nav.hallOfChampions")}</Link>
        <StyledDivider />
        <Link to="/match-history">{t("nav.matchHistory")}</Link>
        <StyledDivider />
        <Link to="/about">{t("nav.about")}</Link>
      </nav>
    </StyledHeader>
  );
};

export default Header;
