import React from "react";
import { useTranslation } from "react-i18next";
import { StyledInformLError } from "./style";

export type Props = {
  error: Error
};

const InformError: React.FC<Props> = ({}: Props) => {
  const { t } = useTranslation();

  return <StyledInformLError>{t("inform.error")}</StyledInformLError>;
};

export default InformError;
